.container {
  :global(.slick-track)::before, :global(.slick-track)::after {
    display: none;
  }
}

.skeleton {
  height: 373px;
  background-color: white;
  padding: 24px;
}
@primary-color: #1DA57A;@link-color: #1DA57A;