.container {
  width: 100%;


  :global(.ant-space-item):last-child {
    flex: 1;
    text-align: right;
  }
}

.more {
  font-size: 12px;
  color: @primary-color;
}
@primary-color: #1DA57A;@link-color: #1DA57A;