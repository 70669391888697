@import '@/styles/variables.less';

.container {

  border-radius: 9px;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  :global(.avatar) {
    object-fit: contain;
    width: 24px;
    margin-right: 8px;
  }

  :global(.ant-card-cover) {
    height: 60%;
  }
  :global(.ant-card-body) {
    padding: 8px 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.titleContainer {
  color: black;
  font-size: 12px;
  max-lines: 2;
  height: 36px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.creator {
  display: flex;
  margin-top: 8px;
  align-items: center;
  font-size: 12px;

  .nickname {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text {
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
  }
}

.actions {
  flex-shrink: 0;

  :global(.anticon-heart) {
    margin-right: 3px;
    color: @accentColor;
    font-size: 14px;
  }

  .number {
    font-size: 14px;
  }

  a {
    color: gray;
  }
}

.imageContainer {
  object-fit: cover;
  height: 100%;
}

@primary-color: #1DA57A;@link-color: #1DA57A;