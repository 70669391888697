.container {
  overflow: scroll;
  background: white;
  border-radius: 12px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  height: 122px;
}

.avatar {
  object-fit: contain;
  height: 68px;
  width: 68px;
  border-radius: 9999px;
  margin-bottom: 4px;
}

.userContainer {
  text-align: center;
  margin: 8px;
  height: 90px;
  width: 90px;
}

.nickname {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  color: black;
}

@primary-color: #1DA57A;@link-color: #1DA57A;