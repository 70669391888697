@import "@/styles/variables";

.settingContainer {
    padding: 12px;
    width: 100%;
    background-color:white;
    align-items: center;

    img {
        object-fit: contain;
        height: 32px;
    }
}

.publish {
    color: white;
    padding: 6px 10px;
    border-radius: 9999px;

    :global(.text) {
        margin-left: 2px;
    }
}

.goToAsk {
    background-color: @primary-color;
}

.goToShow {
    background-color: @accentColor;
}

.inputSearch{
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    background-image: url('/src/images/search.png');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 10px center;
    height: 30px;
    border-radius: 23px;
    border: 1px solid lightgray;
    box-shadow: none;
    text-indent: 2em;
    white-space: nowrap;
    padding: 5px;
    outline: none;
}

@primary-color: #1DA57A;@link-color: #1DA57A;