
.container {
  height: 373px;

  img {
    object-fit: contain;
    width: 100%;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;