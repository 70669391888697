.container {
  width: 100%;
}

.tabContainer {
  margin-bottom: 12px;
  display: flex;
}

.hotList {
  padding-left: 0px;
  list-style: none;
  counter-reset: item;
}


.topRank {
  counter-increment: item;
  color: var(--quick-silver);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.50px;
  line-height: 21px;
  white-space: nowrap;
  text-align: left;

  text-overflow: ellipsis;
  overflow: hidden;

  &::before {
    content: counter(item) ".";
  }
  a {
    color: inherit;
  }
}

.tabTitle {
  color: #9ED55F;

  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  padding-bottom: 8px;
  border-bottom: 2px solid #9ed55F;
}

.refreshControl {
  color: var(--celeste);
  font-style: normal;
  background-color: transparent;
  line-height: 35.14px;
}

.hotSearchText {
  color: #a8a8a8;

  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.40px;
  line-height: 36px;
  margin-bottom: -14px;
  margin-top: 4px;
  min-height: 22px;
}

.topRank:first-child {
  &::before {
    color: #f22b2b;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
  }


}

.topRank:nth-child(2) {
  &::before {
    color: #f28e2b;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
  }
}

.topRank:nth-child(3) {
  &::before {
    color: #f2cc2b;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
  }
}



@primary-color: #1DA57A;@link-color: #1DA57A;