@import "../../../styles/variables";

.container {
  width: @screenWidth;
  margin: 0 auto;
  margin-top: 16px;
}

.content {
  margin: 16px 0;
}

.skeleton {
  background-color: white;
  padding: 24px;
}

.title {
  font-weight: bold;
  font-size: 36px;
}

.author {
  margin: 16px 0;
}

.carousel {
}

.bannerContainer {
  height: 800px;
  background-color: black;
  > div {
    height: 100%;
    display: flex;
    align-items: center;
  }
  img {
    object-fit: contain;
  }

}

.banner {
  object-fit: contain;
  width: 100%;
}

@primary-color: #1DA57A;@link-color: #1DA57A;