.container {
  width: 100%;
}

.tabContainer {
  margin-bottom: 12px;
  display: flex;
}

.tabTitle {
  color: #9ED55F;

  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  padding-bottom: 8px;
  border-bottom: 2px solid #9ed55F;
}

.refreshControl {
  color: var(--celeste);

  font-style: normal;
  background-color: transparent;
  line-height: 35.14px;
}

.topicTitle {
  font-weight: bold;
  font-size: 16px;
}

.topicMetaInfo {
  margin-top: 12px;
  font-size: 12px;
  color: gray;
}

@primary-color: #1DA57A;@link-color: #1DA57A;