@import '@/styles/variables.less';

.container {
  img {
    object-fit: contain;
    height: 42px;
    width: 42px;
    border-radius: 9999px;
    margin-right: 8px;
  }

  .nickname {
    font-weight: bold;
    font-size: 16px;
  }

  .intro {
    font-size: 12px;
  }
  align-items: start;
}

.follow {
  color: @accentColor;
  border: 1px solid @accentColor;
  padding: 2px 12px;
  border-radius: 1000px;
}

.followed {
  color: lightgray;
  border: 1px solid lightgray;
  padding: 2px 12px;
  border-radius: 1000px;
}


.time {
  display: inline-block;
  color: gray;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 8px;
}


@primary-color: #1DA57A;@link-color: #1DA57A;