.container {
  background: white;
  align-items: center;
}

.tab {
  font-size: 16px;

  a {
    color: lightgray;
  }

  :global(.active) {
    color: #1DA57A;
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;