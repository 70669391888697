@import "../../styles/variables";

.background {
  background-image: url('/src/images/login_bg.png');
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.content {
  width: @screenWidth;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.navbar {
  :global(.ant-divider-vertical) {
    border-left: 1px solid rgba(255, 255, 255, 0.6);
  }
  a {
    color: white;
    font-size: 14px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;