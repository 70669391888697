.container {

}

.title {
  height: 36px;
}

.submit {
  width: 20%;
}

@primary-color: #1DA57A;@link-color: #1DA57A;