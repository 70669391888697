.tab {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 6px;

  a {
    color: gray;
  }

  > div {
    flex: 1
  }
}

.metaInfo {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  > img {
    object-fit: contain;
    width: 128px;
  }
  .contact{
    color: var(--celeste);
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.05px;
    white-space: nowrap;
  }
}


@primary-color: #1DA57A;@link-color: #1DA57A;