@import "../../styles/variables";

.background {
  background-image: url('/src/images/login_bg.png');
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container {
  width: @screenWidth;
  margin: 16px auto 0;

}

.logo {
  object-fit: contain;
  height: 64px;
}

.loginButton {
  width: 100%;
  margin: 8px auto 0;
}

.formContainer {
  align-content: center;
  width: 400px;
  margin: 50px auto;
}

.newUser {
  display: flex;
}

.oauth {
  display: flex;
  align-items: center;
  color: gray;
  span {
    margin-left: 8px;
  }

  .icon {
    font-size: 28px;
  }
}

.phoneField {
  :global(.ant-input-group-addon:last-child) {
    padding: 0;
    border: none;
  }
  :global(.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector) {
    border-color: #d9d9d9 !important;
  }
  :global(.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector),
  :global(.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector){
    box-shadow: none;
  }
  :global(.ant-input-group-wrapper-status-error .ant-input-group-addon) {
    border-color: #d9d9d9 !important;
    border: none;
  }
  :global(.ant-btn) {
    border-radius: 0 2px 2px 0;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;