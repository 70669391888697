.container {
  color: inherit;
}

.creator {

  margin: 4px 0;

  :global(.avatar) {
    object-fit: contain;
    width: 18px;
    border-radius: 100px;
  }

  .nickname {
    font-size: 14px;
  }
}

.topicTag {
  color: var(--celeste);
}

.topicTitle {
  font-weight: bold;
  color: black;
  font-size: 22px;
}

.content {
  font-size: 14px;
  color: gray;
  margin-bottom: 16px;
  display: block;
}

.banner {
  text-align: center;
  img,video {
    width: 240px;
    height: 120px;
    object-fit: contain;
  }
}

.metaInfo {
  color: lightgray;
  margin-top: 8px;
  font-size: 12px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;