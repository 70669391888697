
.container {

  img {
    height: 24px;
    width: 24px;
    object-fit:contain;
    margin-right: 8px;
  }

  a {
    color: gray;
    font-size: 16px;
  }

  :global(.ant-space-item) {
    flex: 1;
    text-align: center;
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;