
.pageContainer {
  padding: 48px 0;
  text-align: center;
  margin: 0 auto;
  width: 1080px;
}

.title {
  padding-bottom: 24px;
  margin-bottom: 16px;
  border-bottom: 5px yellow solid;
}

.intro {
  color: white;
  font-size: 16px;
  line-height: 2;
  margin-bottom: 16px;
  text-align: left;
}

.logo {
  object-fit: contain;
  height: 400px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;