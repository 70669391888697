/* @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css"); */
:root {
  --atlantis: #8dce44;
  --atlantis-2: #8dcf44;
  --atlantis-3: #8bcb43;
  --black: #000000;
  --black-haze: #f6f6f6;
  --celeste: #d0d0d0;
  --conifer: #9ed55f;
  --dove-gray: #707070;
  --granite-gray: #666666;
  --mountain-mist: #979797;
  --nobel: #b3b3b3;
  --ocean-green: #3eb871;
  --onyx: #393939;
  --celeste-2: #cccccc;
  --quick-silver: #a8a8a8;
  --ship-gray: #3c3c3e;
  --white: #ffffff;

  --font-size-l: 20px;
  --font-size-m: 14px;
  --font-size-s: 15px;
  --font-size-xl: 21px;
  --font-size-xs: 14px;
  --font-size-xxl: 25px;
  --font-size-xxxl: 26px;
}

body {
  margin: 0;
  background-color: #f0f2f5;
}

#root {
  height: 100%;
  text-align: center;
  > * {
    text-align: left;
  }
}

.App {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  overflow: hidden;
  width: 100%;
}

* {
  box-sizing: border-box;
}

#app-header {
  background-image: url('@/images/bg.png') !important;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  padding: 0 256px;
  height: 64px;
  align-items: center;
}

.ant-tag {
  border-radius: 999px;
  border: none;
}

.banner_image {
  height: 100%;
  width: 100%;
}

.show_video {
  height: 281px;
  width: 369px;
  border-radius: 10px;
  object-fit: fill;
}

.detail-content {
  width: 100%;
}

.detail-content img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.text-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 13px;
  margin-top: 4.5px;
  min-height: 50px;
  width: 124px;
}

.xxbQrcode {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

.contact p {
  padding: 0px;
  margin: 0px;
}

.spacer {
  flex: 1;
}

.App-layout {
  width: 100%;
}

.site-layout-content {
  min-height: 500px;
  width: 100%;
  background-color: transparent;
}

.footer-layout-content {
  letter-spacing: 0.70px;
  line-height: 28px;
  margin-left: 2.0px;
  margin-top: 64px;
  min-height: 44px;
  text-align: center;
  color: #707070;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background-color: white;
  width: 100%;
}

.notFound {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  left: 200px;
  top: 10px;
  position: absolute;
}

.notFoundImgDiv {
  width: 100%;
  height: 100%;
  position: absolute;
}

.notFoundTextDiv {
  top: 180px;
  left: 30px;
  position: absolute;
}

.notFoundImg {
  width: 180px;
  height: 180px;
  object-fit: contain;
}

.error {
  color: red;
}

.loader {
  width: 100%;
  margin: 8px auto;
}



.dropdown {
  z-index: 5;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
  inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  min-width: 268px;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  width: 200px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}

.link-editor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor div.link-edit {
  background-image: url(components/Editor/images/icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.link-card {
  display: flex;
  margin: 0 auto;
  width: 400px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 0 5px lightgrey;

  .icon {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }

  .card-body {
    text-align: left;
  }
  header {
    font-weight: bold;
    color: rgb(13, 13, 13);
    margin-bottom: 2px;
  }
  p {
    color: rgb(208, 208, 208);
    height: 41px;
    overflow: hidden;
    margin: 0;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;