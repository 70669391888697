.container {
  :global(.avatar) {
    object-fit: contain;
    height: 48px;
    width: 48px;
    flex-basis: 64px;
  }
}

.metaInfo {
  margin-bottom: 16px;
  flex-wrap: nowrap;
}

.nickname {
  font-size: 16px;
  font-weight: bold;
}

.metaInfoTextContainer {
}

.title {
  font-size: 14px;
  word-break:break-all;
}

.relationship {
  width: 100%;
  text-align: center;
  margin-bottom: 16px;

  :global(.ant-space-item) {
    flex: 1;
  }
}

.view {
  display: block;
  text-align: center;
}

@primary-color: #1DA57A;@link-color: #1DA57A;