@import '@/styles/variables.less';

.actions {
  flex-shrink: 0;

  :global(.anticon-heart) {
    margin-right: 3px;
    font-size: 14px;
  }

  &.isCollect :global(.anticon){
    color: @accentColor;
  }

  color: gray;

  .number {
    font-size: 14px;
  }

  a {
    color: gray;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;