.container {
  display: flex;
  align-items: center;
  height: 36px;
}

.container img{
  height: 36px;
  width: 36px;
}

.nickname {
  margin-left: 18px;
}

.intro {
  opacity: 0.3;
}

@primary-color: #1DA57A;@link-color: #1DA57A;