
.flowItem {
  width: 100%;
}

.flowContainer {
  width: 100%;
  :global(.ant-space-item) {
    flex: 1;
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;