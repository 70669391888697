.container {
  padding: 16px;
  display: flex;
  background-color: white;
  margin: 16px 0;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 7%) 0 0 20px;
  align-items: center;

  > span {
    font-size: 16px;
  }

  > img {
    object-fit: contain;
    height: 16px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;