@import "@/styles/variables";

.container {
  width: @screenWidth;
  margin: 16px auto 0;
}

.contentBottom {
  display: flex;
  align-items: center;

  img {
    object-fit: contain;
    height: 24px;
    margin: 0 4px;
  }
}

.title {
  font-weight: bold;
  color: black;
  font-size: 22px;
  margin-bottom: 8px;
}

.contentMetaInfo {
  color: lightgray;
}

.skeleton {
  background-color: white;
  padding: 24px;
}

.author {
  margin: 16px 0;
}

.actions {

}

:global {
  video {
    max-width: 100%;
    max-height: 800px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;