.container {
  background-color: transparent; /*背景色：透明*/
  mix-blend-mode: normal; /*混合模式：正常*/
  overflow-x: auto; /*溢出-x：自动*/
}

.skeleton {
  background: white;
  width: 190px;
  height: 250px;
  display: block;
  border-radius: 9px;
  box-sizing: border-box;
  overflow: hidden;

  :global(.ant-skeleton-image) {
    width: 190px;
    height: 150px;
  }

  .skeletonContent {
    padding: 16px 8px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;