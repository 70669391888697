@import "../../styles/variables.less";

.container {
  background-image: url('/src/images/bg.png') !important;
  background-repeat: no-repeat;
  width: 100%;
}

.content {
  width: @screenWidth;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 64px;
}

.navbar {
  :global(.ant-divider-vertical) {
    border-left: 1px solid rgba(255, 255, 255, 0.6);
  }
  a {
    color: white;
    font-size: 14px;
  }
}


.nav {
  width: @screenWidth;
  margin: 0 auto;
  background-color: white;
}

@primary-color: #1DA57A;@link-color: #1DA57A;