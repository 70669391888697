@import "@/styles/variables";

.container a {
  text-decoration: none;

  display: inline-block;
  padding: 8px;

  > img {
    width: 19px;
    object-fit: contain;
  }

  > span {
    color: rgba(0, 0, 0, 0.5);
  }

}

.like {

}

@primary-color: #1DA57A;@link-color: #1DA57A;